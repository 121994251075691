import {scrollLock} from '../../components/scroll-lock/js/scroll-lock.js';

const menuToggle = document.querySelector(".main-nav__toggle");
const menuList = document.querySelector(".main-nav__list");
const logo = document.querySelector('.header--inner .header__logo img');

if (menuToggle) {
  menuToggle.addEventListener("click", () => {
    menuList.classList.toggle("main-nav__list--is-active");
    menuToggle.classList.toggle("main-nav__toggle--is-active");
    logo.classList.toggle('no-filter')
    console.log(logo);


    if (menuList.classList.contains('main-nav__list--is-active')) {
      window.scrollLock.disableScrolling()
    } else {
      window.scrollLock.enableScrolling()
    }
  });
}
