const form = document.getElementById("form");
const section = document.querySelector(".results");

if (form) {
  async function populate() {
    const requestURL = "js/items.json";
    const request = new Request(requestURL);

    const response = await fetch(request);

    const superHeroesText = await response.text();

    const superHeroes = JSON.parse(superHeroesText);
    console.log(superHeroes);
    populateHeroes(superHeroes);
  }

  function getResults() {
    let agreeLenght = document.querySelectorAll(
      ".form__input-check-field--agree input[type=radio]:checked"
    ).length;
    let notAgreeLenght = document.querySelectorAll(
      ".form__input-check-field--not-agree input[type=radio]:checked"
    ).length;

    const resultsBlock = document.querySelector(".results__text");

    const resultsText = document.createElement("p");
    section.style.display = "block";

    if (agreeLenght < notAgreeLenght) {
      resultsText.innerHTML =
        "В конечном счете, вы согласны со Шлиманом, несмотря ни на что. Проверьте, какой процент пользователей считает так, как вы. Шлиман – отличный пример опыта, терпения, твердого характера и уверенности. Как сказала одна из участниц нашего проекта: «Я теперь всегда буду вспоминать о нем, сталкиваясь со сложностями». Важно помнить, что ваша Троя где-то рядом, главное – не пропустить ее в погоне за мечтой.";

      resultsBlock.insertBefore(resultsText, resultsBlock.firstChild);
    } else if ((agreeLenght = notAgreeLenght)) {
      resultsText.innerHTML =
        "Цену, которую заплатил Шлиман за свои находки, вы считаете слишком высокой. Проверьте, какой процент пользователей считает так, как вы. Ему следовало быть аккуратнее, не полагаться только на судьбу и не играть в античного героя XIX века. Осторожность и внимание, умение общаться с учеными и работать в команде, возможно, отсрочили бы открытие, но сохранили то, что он сам искал. Но важно знать, что Троя всегда рядом.";

      resultsBlock.insertBefore(resultsText, resultsBlock.firstChild);
    } else {
      resultsText.innerHTML =
        "Вы не можете однозначно оценить действия Генриха Шлимана. Проверьте, какой процент пользователей считает так, как вы. И это понятно, несмотря на поиски мечты, каждый раз он делал выбор. Вывод, который хочется сохранить: наша Троя всегда где-то рядом, главное – не потерять ее.";
      resultsBlock.insertBefore(resultsText, resultsBlock.firstChild);
    }
  }

  async function populateHeroes(obj) {
    const heroes = obj;

    const sum = Object.values(heroes).reduce((acc, bill) => {
      for (const [name, value] of Object.entries(bill)) {
        const sumKey = `${name}Sum`;
        if (acc[sumKey] === undefined) acc[sumKey] = 0;
        acc[sumKey] += value;
      }
      return acc;
    }, {});

    for (const hero of heroes) {
      const myArticle = document.createElement("article");
      const heroTitle = document.createElement("h2");
      const score = Math.round((hero.value * 100) / sum.valueSum);
      const heroTitleScore = document.createElement("div");
      const heroTitleScoreBg = document.createElement("div");
      const heroTitleText = document.createElement("span");
      const heroScoreText = document.createElement("span");

      heroTitleScore.classList.add("score");
      heroTitleScoreBg.style.width = score + "%";
      heroTitleScoreBg.style.background = hero.bg;
      heroTitleText.style.background = hero.bg;

      heroTitleText.textContent = hero.name;
      heroScoreText.textContent = score + "%";
      heroTitle.classList.add("opinions-title");
      heroScoreText.classList.add("score-text");

      heroTitle.appendChild(heroTitleText);
      heroTitleScore.appendChild(heroTitleScoreBg);
      heroTitleScore.appendChild(heroScoreText);
      heroTitle.appendChild(heroTitleScore);

      myArticle.appendChild(heroTitle);

      section.insertBefore(myArticle, section.firstChild);
    }
  }

  const ERROR_SEND_TEST = "Не удалось отправить форму. Попробуйте ещё раз";

  const sendData = (onSuccess, onFail, body) => {
    fetch(form.getAttribute("action"), {
      method: "POST",
      body,
    })
      .then((response) => {
        if (response.ok) {
          onSuccess();
        } else {
          onFail(ERROR_SEND_TEST);
        }
      })
      .catch(() => {
        onFail(ERROR_SEND_TEST);
      });
  };

  form.addEventListener("submit", function (event) {
    event.preventDefault();
    sendData(
      () => {
        form.style.display = "none";
        populate();
        getResults();
      },
      () => {
        alert(ERROR_SEND_TEST);
      },
      new FormData(event.target)
    );
  });

  const feedbackForm = document.querySelector(".feedback");
  const feedbackSuccess = document.querySelector(".feedback-success");

  feedbackForm.addEventListener("submit", function (event) {
    let data = this;
    event.preventDefault();
    form.reset();
    fetch(data.getAttribute("action"), {
      method: data.getAttribute("method"),
      body: new FormData(data),
    })
      .then((res) => res.text())
      .then(function (data) {
        section.style.display = "none";
        feedbackSuccess.style.display = "flex";
      });
  });
}
