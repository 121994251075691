import "./modules/nav-menu.js";
import "./modules/form";
// import "./modules/quiz.js"
import Plyr from "plyr";
import { gsap, Power4 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
import '../components/modals/js/index';
import { Fancybox } from "@fancyapps/ui";
document.addEventListener("DOMContentLoaded", function (event) {
  const readMoreBtn = document.querySelectorAll(".read-more");

  if (readMoreBtn.length > 0) {
    readMoreBtn.forEach((btn) => {
      btn.addEventListener("click", () => {
        const btnDataAttr = btn.getAttribute("data-read");
        document
          .querySelector(`[data-content='${btnDataAttr}']`)
          .classList.add("is-active");

        btn.remove();
      });
    });
  }

  let tl = gsap.timeline();
  let mm = gsap.matchMedia();

  mm.add("(min-width: 1200px)", () => {
    if (document.querySelector(".page--main")) {
      tl.fromTo(
        ".page__body",
        0.5,
        { autoAlpha: 0 },
        {
          autoAlpha: 1,
          ease: "power4.out",
        }
      )
        .fromTo(
          ".page--main .header",
          0.3,
          { autoAlpha: 0 },
          {
            autoAlpha: 1,
            ease: "power4.out",
          }
        )
        .fromTo(
          ".page--main .header__logo",
          0.5,
          { y: 20, autoAlpha: 0 },
          {
            y: 0,
            autoAlpha: 1,
            ease: "power4.out",
          }
        )
        .staggerFromTo(
          ".page--main .main-nav__item",
          0.5,
          { autoAlpha: 0, y: 30 },
          { autoAlpha: 1, y: 0, ease: "power4.out" },
          0.03
        )
        .fromTo(
          ".page--main main",
          0.5,
          { scale: 0.8, autoAlpha: 0 },
          {
            scale: 1,
            autoAlpha: 1,
            ease: "power4.out",
          },
          "-=0.2"
        )
        .fromTo(
          ".page--main .social",
          0.5,
          { autoAlpha: 0 },
          {
            autoAlpha: 1,
            ease: "power4.out",
          },
          "-=0.2"
        );
    }

    if (document.querySelector(".animated-card")) {
      gsap.registerPlugin(ScrollTrigger);
      gsap.set(".animated-card", { autoAlpha: 0, y: 20 });

      ScrollTrigger.batch(".animated-card", {
        interval: 0.2,
        start: "top bottom-=120px",

        onEnter: (batch) => {
          gsap.to(batch, {
            autoAlpha: 1,
            stagger: 0.15,
            overwrite: true,
            y: 0,
            ease: "power4.out",
          });
        },

        onLeaveBack: (batch) =>
          gsap.to(batch, {
            autoAlpha: 0,
            y: 20,
            stagger: 0.15,
          }),
      });
    }

    // function animateFrom(elem, direction) {
    //   direction = direction || 1;
    //   var x = 0,
    //     y = direction * 10;
    //   if (elem.classList.contains("gs_reveal_bottom")) {
    //     // x = -50;
    //     y = -100;
    //   } else if (elem.classList.contains("gs_reveal_top")) {
    //     // x = 50;
    //     y = 100;
    //   }
    //   elem.style.transform = "translate(" + x + "px, " + y + "px)";
    //   elem.style.opacity = "0";
    //   gsap.fromTo(
    //     elem,
    //     { x: x, y: y, autoAlpha: 0 },
    //     {
    //       duration: 1,
    //       y: 0,
    //       autoAlpha: 1,
    //       ease: "power4.out",
    //       overwrite: "auto",
    //     }
    //   );
    // }

    // function hide(elem) {
    //   gsap.set(elem, { autoAlpha: 0 });
    // }

    // gsap.registerPlugin(ScrollTrigger);

    // gsap.utils.toArray(".gs_reveal").forEach(function (elem) {
    //   hide(elem); // assure that the element is hidden when scrolled into view

    //   ScrollTrigger.create({
    //     trigger: elem,
    //     onEnter: function () {
    //       animateFrom(elem);
    //     },
    //     onEnterBack: function () {
    //       animateFrom(elem, -1);
    //     },
    //     onLeave: function () {
    //       hide(elem);
    //     },
    //   });
    // });
  });

  var cards = document.querySelectorAll(".cards__item");

  if (cards) {
    cards.forEach((card) => {
      const faceHeight = card.querySelector(
        ".cards__item-face--front"
      ).offsetHeight;
      const backHeight = card.querySelector(
        ".cards__item-face--back"
      ).offsetHeight;
      console.log(faceHeight);

      if (faceHeight > backHeight) {
        card.style.height = faceHeight + "px";
      } else {
        card.style.height = backHeight + "px";
      }

      card.addEventListener("click", () => {
        cards.forEach((item) => {
          if (item.classList.contains("is-flipped")) {
            item.classList.remove("is-flipped");
          }
        });
        card.classList.add("is-flipped");
      });
    });
  }

  const player = Plyr.setup(".player", {
    title: "Example Title",
    settings: [],
    controls: ["play"],
    hideControls: true,
    iconPrefix: "play-audio-icon",
    iconUrl: "/images/sprite_auto.svg",
  });

  // Expose
  window.player = player;

  for (let i in player) {
    player[i].on("play", function (instance) {
      let source = instance.detail.plyr.source;
      for (let x in player) {
        if (player[x].source != source) {
          player[x].pause();
        }
      }
    });
  }
});
